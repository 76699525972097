var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.pallet_types","resource":"","noFilter":"","creatable":"","deletable":""},on:{"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(){return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){_vm.showModalEdit = true}}})],1)]},proxy:true},{key:"max_height",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.max_height}})],1)]}},{key:"max_length",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.max_length}})],1)]}},{key:"max_weight",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.max_weight}})],1)]}},{key:"max_width",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.max_width}})],1)]}},{key:"weight",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.weight}})],1)]}}])}),_c('PalletTypeModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}}),_c('PalletTypeModal',{attrs:{"show":_vm.showModalEdit,"palletType":_vm.detail},on:{"update:show":function($event){_vm.showModalEdit=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }